<template>
  <div class="confirm">
    <v-row justify="center" align="center">
      <v-col>
        <div class="text-center">
          <vuetify-logo />
        </div>
        <h2>تأكيد رقم الجوال</h2>
        <div class="card">
          <img class="thank-img" src="../assets/images/icons/3.png" />
          <p class="done">تم ارسال كود التحقق الى جوالكم</p>
          <div class="mobile-container">
            <div class="view" v-if="!changeMobile">
              <span class="num" dir="ltr">{{ phoneNumber }}</span>
              <span class="btn-change" @click="changeMobile = true">تغيير</span>
            </div>

            <div class="view new-mobile" v-if="changeMobile">
              <!-- <v-text-field
                class="custom-input"
                v-model="verifyData.mobile"
                solo
                label="رقم الجوال"
              ></v-text-field> -->
              <div class="tel-form">
                <vue-tel-input
                  v-model="verifyData.mobile"
                  type="tel"
                  v-bind="bindProps"
                  required
                />
              </div>
              <span class="btn-change" @click="changeMobileNumber()">حفظ</span>
            </div>
            <v-form ref="codeForm" v-model="validCode">
              <div class="verytify-code">
                <v-text-field
                  v-model="verifyData.verification_code"
                  label="ادخل كود التحقق هنا"
                  hide-details="auto"
                  :rules="[validationRules.required]"
                ></v-text-field>
              </div>
            </v-form>
          </div>
          <a class="btn complete-btn" @click="sendVerifyCode">تأكيد</a>
        </div>
      </v-col>
    </v-row>
    <v-snackbar v-model="snackbar" :color="color" top right>
      {{ messsage }}
    </v-snackbar>
  </div>
</template>

<script>
import { ServiceFactory } from "../services/ServiceFactory";
const service = ServiceFactory.get("register");
const cartService = ServiceFactory.get("cart");
import { validationMixin } from "../mixins/validationMixin";
import VuetifyLogo from "../components/VuetifyLogo.vue";
export default {
  mixins: [validationMixin],
  data: () => ({
    changeMobile: false,
    newMobile: "",
    code: "",
    verifyData: {
      verification_code: "",
      mobile: "",
    },
    validCode: false,
    bindProps: {
      mode: "international",
      disabledFetchingCountry: false,
      disabledFormatting: false,
      defaultCountry: "SA",
      placeholder: "أدخل رقم الهاتف",
      required: true,
      enabledCountryCode: false,
      type: "tel",
      formattedNumber: true,
      autocomplete: "on",
      autofocus: true,
      onlyCountries: ["SA", "UAE", "KW", "OM", "BH", "EG"],
      name: "telephone",
      validCharactersOnly: true,
      dropdownOptions: {
        disabledDialCode: true,
        showFlags: true,
        showDialCodeInList: true,
        showDialCodeInSelection: true,
      },
      inputOptions: {
        // showDialCode: true,
        type: "tel",
        placeholder: "رقم الجوال",
      },
    },
    snackbar: false,
    color: "success",
    messsage: "",
  }),
  components: {
    VuetifyLogo,
  },
  created() {
    // this.fetchAllItems();
    //   if (this.$route) {
    //   }
    this.verifyData.mobile = this.phoneNumber;
  },
  methods: {
    async changeMobileNumber() {
      this.dataLoading = true;

      const auth = {
        mobile: this.verifyData.mobile.split(" ").join(""),
      };
      const signup = await service.signup(auth);
      this.$store.commit("addPhone", {
        phone: this.verifyData.mobile.split(" ").join(""),
      });
      if (signup.status.error === false) {
        // localStorage.setItem("token", signup.data.accessToken);
        // localStorage.setItem("mobile", signup.data.mobile);
        this.changeMobile = false;
      }
    },
    async sendVerifyCode() {
      if (!this.validCode) {
        this.$refs.codeForm.validate();
        return;
      }
      this.dataLoading = true;
      this.verifyData.mobile = this.phoneNumber;
      const signup = await service.sendVerifyCode(this.verifyData);
      if (signup.status.error === false) {
        localStorage.setItem("token", signup.data.accessToken);
        localStorage.setItem("mobile", signup.data.mobile);
        // const creatAddress = await cartService.creatAddress(
        //   signup.user.accessToken,
        //   this.shipping_to
        // );
        this.cartSent;
        // const allData = {
        //   products: this.products,
        //   shipping_to: this.shipping_to,
        //   order_type: this.order_type,
        //   payment_method: this.paymentMethod,
        //   coupon_code: this.discount,
        // };
        let allData = {};
        if (this.order_type == "inRyad") {
          allData = {
            location: {
              lat: this.shipping_to.lat,
              lng: this.shipping_to.lng,
              address: this.shipping_to.address,
            },
            notes: this.shipping_to.notes,
            products: this.products,
            shipping_to: this.shipping_to,
            order_type: this.order_type,
            payment_method: this.paymentMethod,
            coupon_code: this.discount,
          };
        } else {
          // outryad
          allData = {
            notes: this.shipping_to.notes,
            products: this.products,
            shipping_to: {
              city: this.shipping_to.city,
              location: this.shipping_to.address,
              notes: this.shipping_to.notes,
            },
            order_type: this.order_type,
            payment_method: this.paymentMethod,
            coupon_code: this.discount,
          };
        }
        console.log(allData);
        const completeCart = await cartService.checkOut(allData);
        if (completeCart.status.error === false) {
          window.location =
            "/paymentMethod?orderId=" + completeCart.data.order_id;
          // if (completeCart.data.payment_id) {
          //   this.payOnline = true;
          //   this.payment_id = completeCart.data.payment_id;
          // } else {
          //   this.completeCartMassege = "Cart Success";
          //   this.snackbar = true;
          //   this.$router.push("/myOrders");
          // }
        } else {
          this.color = "error";
          this.messsage = completeCart.status.message;
          this.snackbar = true;
        }
      } else {
        console.log("signup.message", signup.status.message);
        this.color = "error";
        this.messsage = signup.status.message;
        this.snackbar = true;
      }
      this.dataLoading = false;
    },
    // async fetchAllItems() {
    //   this.dataLoading = true;
    //   const data = await aboutService.getPolicyData();
    //   this.policy = data.refund_policy;
    //   this.dataLoading = false;
    // },
  },
  computed: {
    phoneNumber() {
      return this.$store.state.phoneNumber.phone;
    },
    totalPrice() {
      return this.$store.state.totalPrice;
    },
    shipping_to() {
      return this.$store.state.shipping_to;
    },
    products() {
      return this.$store.state.products;
    },
    order_type() {
      return this.$store.state.order_type;
    },
    discount() {
      return this.$store.state.discount;
    },
    cartSent() {
      for (var i = 0, len = this.products.length; i < len; i++) {
        delete this.products[i].total;
        delete this.products[i].Kg;
        delete this.products[i].price;
        delete this.products[i].product_name;
      }
      this.products.map((item) => (item.options = []));
      return true;
    },
  },
};
</script>

<style>
.confirm {
  text-align: center;
}
.confirm h2 {
  margin: 50px 0 30px 0;
  text-align: center;
  color: #614c37 !important;
  font-size: 15px !important;
}
.confirm .card {
  box-shadow: 0 6px 15px rgba(0, 0, 0, 0.18);
  background: #fff;
  border-radius: 20px;
  padding: 15px;
  margin-bottom: 30px;
}
.thank-img {
  width: 160px;
}
.confirm .card p {
  font-size: 15px !important;
  color: #5a5a5a;
  font-weight: normal;
  /* font-family: "Roboto", sans-serif !important; */
}
.num {
  background: #dedede;
  padding: 5px 30px;
  border-radius: 0 20px 20px 0;
}
.btn-change {
  background: #fad15a;
  padding: 5px 30px;
  border-radius: 20px 0 0 20px;
  cursor: pointer;
}
.new-mobile .btn-change {
  border-radius: 20px;
}
.verytify-code {
  width: 80%;
  margin: 40px auto;
}
.verytify-code input {
  padding: 20px 0 !important;
}
.complete-btn {
  background: #fad15a !important;
  display: block !important;
  text-align: center !important;
  padding: 9px !important;
  cursor: pointer !important;
  text-decoration: none !important;
  width: 100%;
  border: none !important;
  font-family: "frutiger lt arabic 55 roman" !important;
  color: #444 !important;
  width: 80% !important;
  margin: auto !important;
  border-radius: 7px !important;
}
</style>
